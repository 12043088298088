.create-owner-form {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin-left: 5vw;
  }
  
  .create-owner-form input {
    border: none;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
    height: 3vh;
    width: 80vw;
    outline: none;
    border-color: transparent;
  }
  
  .create-owner-form input + .focus-border {
    position: relative;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #64747b;
    transition: 0.4s;
  }
  
  .create-owner-form input:focus + .focus-border {
    width: 80vw;
    transition: 0.4s;
    margin-top: -10px;
  }
  
  .create-owner-form button {
    border: none;
    height: 6vh;
    width: 85vw;
    background-color: aliceblue;
    border-radius: 8px;
    font-weight: 700;
    color: black;
  }
  