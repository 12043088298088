.home-form {
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.home-form h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.home-form input {
  border: none;
  padding: 7px 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  height: 3vh;
  width: 80vw;
  outline: none;
  border-color: transparent;
  font-size: 16px;
}

.home-form input + .focus-border {
  position: relative;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #64747b;
  transition: 0.4s;
}

.home-form input:focus + .focus-border {
  width: 80vw;
  transition: 0.4s;
  margin-top: -10px;
}

.home-form label{
  text-decoration: underline;
}

.home-form button {
  border: none;
  height: 6vh;
  width: 80vw;
  background-color: rgb(37, 52, 75);
  color: white;
  border-radius: 8px;
  font-weight: 700;
}

.nav-to-signin {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.nav-to-signin a button {
  border: none;
  height: 6vh;
  width: 30vw;
  background-color: rgb(37, 52, 75);
  color: white;
  border-radius: 8px;
  font-weight: 700;
}

.nav-to-signin p {
  margin-bottom: 0;
}

