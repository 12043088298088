.footer-content{
    margin-top: -13vh;
    margin-left: 5vw;
    color: white;
}

.footer-content a{
    color: white;
}

.phone{
    margin-bottom: 0;
}

.footer-content h3 {
    margin-top: 0;
}

.footer-content p {
    margin-top: 0;
    margin-bottom: 0;
}

.footer-container{
    margin-top: 3vh;
}