/* font-family: "Montserrat", sans-serif; */
/* font-family: "Playfair Display", serif; */

body {
  background-color: white;
  font-family: "Montserrat", sans-serif;
}

* {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


