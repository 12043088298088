body {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}
.home-box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: center;
  margin-top: 15px;
}

.box {
  width: 190px;
  height: 190px;
  background-color: #125688;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-box {
  width: 190px;
  height: 190px;
  background-color: #d5dfe7;
}

.box-h1 {
  color: white;
  font-size: 1.5em;
}

.logo {
  width: 100vw;
}

.instagram-container {
  display: flex;
  justify-content: center;
}

.button-to-instagram {
  width: 50vw;
  height: 5vh;
  background-color: transparent;
  border: none;
  text-align: center;
  margin-bottom: 8vh;
}

.link-to-instagram {
  text-decoration: none;
  color: black;
}

.link-to-instagram p {
  display: flex;
  align-items: center;
  background: #125688;
  justify-content: center;
  padding: 5px;
  border-radius: 20px;
  color: white;
  font-weight: 600;
}

@keyframes zoomIn {
  0% {
    transform: scale(0, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}

#bottom-line-1 {
  opacity: 0;
  stroke: #f99417;
  animation-duration: 1s;
  animation-delay: 3.2s;
}
#bottom-line-3 {
  opacity: 0;
  stroke: #f99417;
  animation-duration: 0.8s;
  animation-delay: 3.5s;
}
#bottom-line-2 {
  opacity: 0;
  stroke: #f99417;
  animation-duration: 0.8s;
  animation-delay: 3.8s;
}

@keyframes line-animation {
  from {
    opacity: 1;
    stroke-dashoffset: 1;
  }
  to {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

@keyframes fade-in {
  to {
    opacity: 1;
    filter: blur(0);
  }
}

.hello-user {
  color: black;
  max-width: 40ch;
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  margin: 0 5px;
}

span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
