.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  z-index: 3;
  width: 30px;
  position: relative;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: transform 0.3s, opacity 0.3s;
}

.open .line:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
  background-color: white;
}

.open .line:nth-child(2) {
  opacity: 0;
}

.open .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
  background-color: white;
}

.menu {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  background-color: rgb(37,52,75); 
  width: 100%;
  transition: transform 0.3s, opacity 0.3s;
  transform: translateY(-100%);
  opacity: 0;
  z-index: 2;
  top: 0;
}

.menu.open {
  display: flex;
  transform: translateY(0%);
  opacity: 1;
  justify-content: center;
  height: 100vh;
  position: fixed;
}

.menu-item {
  padding: 10px;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: larger;
}

.signout-button {
  background-color: transparent;
  border: none;
  font-size: large;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  cursor: pointer;
}
