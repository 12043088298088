.landing-main {
  display: flex;
  width: 100vw;
  height: auto;
  justify-content: center;
  margin-top: 5vh;
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.welcome-img {
  width: 70vw;
  margin-bottom: 5vh;
  animation: fadeDown 0.5s ease-in-out forwards;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.logo-text {
  text-align: center;
  margin-top: -5vh;
  font-family: "Inria", serif;
  font-size: 20px;
  margin-bottom: 0;
}

.logo-text-2 {
  text-align: center;
  font-size: 10px;
  font-family: "Playwrite CU", cursive;
  margin-bottom: 0;
  margin-top: 0;
}

.link-to-signin {
  border: 0.5px solid black;
  height: 6vh;
  width: 43vw;
  background-color: white;
  color: black;
  border-radius: 8px;
  font-weight: 700;
  font-family: "Inria", serif;
}

.gallery {
  margin-top: 3vh;
}
